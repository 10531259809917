<!--  -->
<template>
  <div class="Journalism">
    <!-- 导航栏 -->
    <Navigation />

    <div class="news">
      <div class="newsEnglish">
        {{ $globalVar.menu3_desc1 }}&nbsp;{{ $globalVar.menu3_desc2 }}
      </div>
      <div class="newsChinese">{{ $globalVar.menu3 }}</div>
    </div>

    <div class="mains">
      <div
        class="content"
        v-for="ele in newsInfo"
        :key="ele.time"
        @click="lookDetails(ele.id)"
      >
        <div class="content-img">
          <img :src="ele.newsImage" />
        </div>

        <!-- 新闻展示 -->
        <div class="content-Text">
          <div class="title oneSpill">{{ ele.newsTitle }}</div>
          <div class="introduce twoSpill">{{ ele.newsDesc }}</div>
          <div class="line"></div>

          <div class="release">
            <div class="time">{{ ele.newsDate }}</div>
            <div class="look">
              <img :src="require('@/assets/img/Journalism/goto1.png')" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="listFalg" v-if="listFalg">我也是有底线的！！！！</div>
  </div>
</template>

<script>
import Navigation from "../../components/Navigation.vue"; // 导航栏组件
import { Loading } from "element-ui";
export default {
  components: {
    Navigation,
  },
  data() {
    return {
      newsInfo: [],
      isClick: true,
      //分页
      currentPage: 1, //当前页
      count: 0, //总条数
      pageSize: 4, //每页显示条数
      falg: true, //开关数据加载
      listFalg: false, //没有数据提示
      loading: "", //数据加载中
    };
  },
  methods: {
    // 查看资讯详情
    lookDetails(id) {
      this.$router.push({ path: `/newDetails?id=${id}` });
    },
    //列表请求
    http(val) {
      this.$api
        .newsList({ begin: val, pageNum: this.pageSize, newsState: 0 })
        .then((res) => {
          if (res.code == 0) {
            res.data.forEach((ele) => {
              this.newsInfo.push(ele);
            });
            this.count = Number(res.count);
            //关闭加载数据
            if (this.falg == false) {
              this.loading.close();
            }
            this.falg = true;
          } else {
            console.log("获取失败，请稍后再试！");
          }
        })
        .catch((err) => {
          console.log("网络出错，请稍后再试！");
        });
    },
    //加载中
    startLoading() {
      // Loading.service(options); options 参数为 Loading 的配置项
      // 使用loading变量来接收Loading.service返回的实例

      this.loading = Loading.service({
        lock: true, //是否锁定
        text: "拼命加载中...", //显示在加载图标下方的加载文案
        background: "rgba(0,0,0,.7)", //遮罩背景色
      });
    },
  },
  mounted() {
    this.http(this.currentPage);
    let _this = this;
    window.onscroll = function () {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight > scrollHeight - 1) {
        if (_this.falg) {
          if (_this.count > _this.newsInfo.length) {
            //开启加载数据提示
            _this.startLoading();
            //页数++
            _this.currentPage++;
            //请求数据
            _this.http(_this.currentPage);
            _this.falg = false;
          } else {
            _this.listFalg = true;
          }
        }
      }
    };
  },
};
</script>
<style lang="less" scoped>
.Journalism {
  width: 100%;
  background-color: #fff;
  .listFalg {
    text-align: center;
    padding: 0 20px 20px 20px;
    color: #b3b3b3;
  }
  // 一行文字溢出
  .oneSpill {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // 两行文字溢出
  .twoSpill {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  // 三行文字溢出
  .threeSpill {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  @media (min-width: 1280px) {
    .news {
      position: relative;
      background: url("../../assets/img/Journalism/news.jpg") no-repeat;
      background-size: 10rem 100%;
      padding: 1.4rem 2.93rem 1.4219rem 3.375rem;
      text-align: center;
      .newsEnglish {
        font-size: 0.17rem;
        font-weight: 400;
        letter-spacing: 0.08rem;
        padding-bottom: 0.2rem;
        color: rgba(255, 255, 255, 1);
      }
      .newsChinese {
        font-size: 0.2rem;
        font-weight: 500;
        color: #ffffff;
        letter-spacing: 0.08rem;
      }
    }
    .mains {
      width: 64%;
      margin: 0.4rem auto 0;
      .content {
        height: 100%;
        box-shadow: 0px 0.02rem 0.07rem 4px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin-bottom: 0.25rem;
        position: relative;
        // 详情的图片
        .content-img {
          position: absolute;
          top: 0;
          left: 0;
          img {
            border-radius: 5px 0 0 5px;
            width: 1.96rem;
            height: 1.6rem;
          }
        }
        // 详情的内容
        .content-Text {
          width: 3.55rem;
          height: 1.6rem;
          display: inline-block;
          padding-left: 2.4rem;
          position: relative;
          .title {
            line-height: 0.2345rem;
            padding-top: 0.18rem;
            font-size: 0.13rem;
            font-weight: bold;
            color: #222222;
          }
          .introduce {
            line-height: 0.175rem;
            font-size: 0.104rem;
            font-weight: 500;
            color: #666666;
            margin-top: 0.12rem;
          }
          .line {
            width: 3.55rem;
            height: 1px;
            position: absolute;
            top: 1.15rem;
            background: #e4e4e7;
          }
          .release {
            width: 3.55rem;
            display: inline-block;
            margin: 0.208rem 0;
            position: absolute;
            top: 1.1rem;
            .time {
              width: 1.2rem;
              height: 0.0729rem;
              font-size: 0.0938rem;
              font-weight: bold;
              color: #999999;
              display: inline-block;
            }
            .look {
              float: right;
              img {
                width: 0.1406rem;
                height: 0.0625rem;
              }
            }
          }
        }
        .content-Text:hover {
          cursor: pointer;
        }
      }
    }
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    .news {
      position: relative;
      background: url("../../assets/img/Journalism/news.jpg") no-repeat;
      background-size: 100% 100%;
      padding: 1.4rem 2rem 1.4219rem 2rem;
      text-align: center;
      .newsEnglish {
        font-size: 0.17rem;
        font-weight: 400;
        letter-spacing: 0.08rem;
        padding-bottom: 0.2rem;
        color: rgba(255, 255, 255, 1);
      }
      .newsChinese {
        font-size: 0.2rem;
        font-weight: 500;
        color: #ffffff;
        letter-spacing: 0.08rem;
      }
    }
    .mains {
      width: 80%;
      margin: 0.4rem auto 0;
      .content {
        height: 100%;
        box-shadow: 0px 0.02rem 0.07rem 4px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin-bottom: 0.25rem;
        position: relative;
        // 详情的图片
        .content-img {
          position: absolute;
          top: 0;
          left: 0;
          img {
            border-radius: 5px 0 0 5px;
            width: 1.96rem;
            height: 1.7rem;
          }
        }
        .content-Text {
          padding-left: 2.6rem;
          position: relative;
          width: 4.3rem;
          height: 1.7rem;
          display: inline-block;
          .title {
            line-height: 0.3rem;
            padding-top: 0.18rem;
            font-size: 0.176rem;
            font-weight: bold;
            color: #222222;
          }
          .introduce {
            font-size: 0.15rem;
            height: 0.5rem;
            line-height: 0.266rem;
            margin-top: 0.11rem;
          }
          .line {
            width: 4.3rem;
            height: 2px;
            position: absolute;
            top: 1.25rem;
            background: #e4e4e7;
          }
          .release {
            width: 4.3rem;
            display: inline-block;
            margin: 0.208rem 0;
            position: absolute;
            top: 1.2rem;
            .time {
              width: 1.9rem;
              height: 0.0729rem;
              font-size: 0.15rem;
              font-weight: bold;
              color: #999999;
              display: inline-block;
            }
            .look {
              float: right;
              img {
                width: 0.2rem;
                height: 0.1rem;
              }
            }
            .look:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  @media (min-width: 751px) and (max-width: 1023px) {
    .news {
      position: relative;
      background: url("../../assets/img/Journalism/news.jpg") no-repeat;
      background-size: 10rem 100%;
      padding: 1.4rem 1.5rem 1.4219rem 1.6rem;
      text-align: center;
      .newsEnglish {
        font-size: 0.2rem;
        font-weight: 400;
        letter-spacing: 0.08rem;
        padding-bottom: 0.2rem;
        color: rgba(255, 255, 255, 1);
      }
      .newsChinese {
        font-size: 0.24rem;
        font-weight: 500;
        color: #ffffff;
        letter-spacing: 0.08rem;
      }
    }
    .mains {
      width: 81%;
      margin: 0.4rem auto 0;
      .content {
        height: 100%;
        box-shadow: 0px 0.02rem 0.07rem 4px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin-bottom: 0.3rem;
        position: relative;
        // 详情的图片
        .content-img {
          position: absolute;
          top: 0;
          left: 0;
          img {
            border-radius: 5px 0 0 5px;
            width: 2.5rem;
            height: 2.1rem;
          }
        }
        .content-Text {
          padding-left: 2.76rem;
          position: relative;
          width: 3.125rem;
          height: 2.1rem;
          display: inline-block;
          .title {
            line-height: 0.31rem;
            padding-top: 0.2rem;
            font-size: 0.2rem;
            font-weight: bold;
            color: #222222;
          }
          .introduce {
            width: 3.125rem;
            font-size: 0.16rem;
            line-height: 0.25rem;
            margin-top: 0.16rem;
          }
          .line {
            width: 3.125rem;
            height: 2px;
            position: absolute;
            top: 1.53rem;
            background: #e4e4e7;
          }
          .release {
            width: 3.125rem;
            display: inline-block;
            position: absolute;
            top: 1.73rem;
            .time {
              width: 1.9rem;
              height: 0.0729rem;
              font-size: 0.16rem;
              font-weight: bold;
              color: #999999;
              display: inline-block;
            }
            .look {
              float: right;
              img {
                width: 0.2rem;
                height: 0.1rem;
              }
            }
            .look:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  @media (min-width: 320px) and (max-width: 750px) {
    .news {
      margin-top: 0.615rem;
      background: url("../../assets/img/h5/Journalism/banner_bg.png") no-repeat;
      background-size: 100% 100%;
      padding: 1.43rem 1.25rem 1.4rem 1.18rem;
      text-align: center;
      color: #fff;
      .newsEnglish {
        font-size: 0.24rem;
        font-weight: bold;
        letter-spacing: 0.095rem;
      }
      .newsChinese {
        font-size: 0.36rem;
        font-weight: bold;
        letter-spacing: 0.08rem;
        padding-top: 0.14rem;
      }
    }
    .mains {
      width: 100%;
      padding: 0.5rem 0.5rem 0;
      box-sizing: border-box;
      .content {
        height: 100%;
        box-shadow: 0px 0.02rem 0.085rem 2px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        margin-bottom: 0.4rem;
        position: relative;
        // 详情的图片
        .content-img {
          position: absolute;
          top: 0;
          left: 0;
          img {
            border-radius: 5px 0 0 5px;
            width: 2.36rem;
            height: 2.14rem;
          }
        }
        // 详情的内容
        .content-Text {
          height: 2.14rem;
          padding-left: 2.8rem;
          position: relative;
          width: 3.3rem;
          display: inline-block;
          .title {
            padding-top: 0.25rem;
            width: 3.36rem;
            font-size: 0.3rem;
            line-height: 0.4rem;
            font-weight: 600;
            color: #222222;
          }
          .introduce {
            padding-top: 0.16rem;
            line-height: 0.37rem;
            font-size: 0.27rem;
          }
          .line {
            display: none;
          }
          .release {
            .time {
              position: relative;
              top: 0.2rem;
              width: 2.7rem;
              height: 0.18rem;
              font-size: 0.26rem;
              font-weight: 400;
              color: #999999;
            }
            .look {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
